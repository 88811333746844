import $ from 'jquery';
import select2 from 'select2';
import {axiosApi} from "./env";

export default async function loadStores() {
    const stores = await axiosApi.get(`stores.json`);
    for (const element of $(".store_list")) {
        const active = await axiosApi.get(`stores/active.json?show_all=${$(element).data('all')}`);
        $(element).select2({
            placeholder: 'Select Store',
            allowClear: true,
            data: [{id: '', text: ''},
                {
                    "text": "Active",
                    "children": stores.data.filter(store => active.data.map(store => store.id).includes(parseInt(store.id)))
                        .map(store => {
                                return {disabled: $(element).data('all'), id: store.id, text: `${store.storeName} (${store.id})`}
                            }
                        )
                },
                {
                    "text": "Unregistered",
                    "children": stores.data.filter(store => !active.data.map(store => store.id).includes(parseInt(store.id)))
                        .map(store => {
                            return {id: store.id, text: `${store.storeName} (${store.id})`}
                        })
                }
            ]
        });

        $(element).val($(element).data('selected')).trigger('change')
    }
}