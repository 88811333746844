require("@rails/ujs").start()
require('bootstrap/dist/js/bootstrap')
global.moment = require('moment');

import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
import loadStores from "../utils/storeList"

$(document).ready(function () {
    loadStores();


    if (navigator.serviceWorker) {
        console.log("Service worker is ok")
        navigator.serviceWorker.ready
            .then((swReg) => {
                console.log("When SW is ready");
                //Base64.urlsafe_decode64(Rails.application.credentials.vapid[:public_key]).bytes
                return swReg.pushManager
                    .subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: new Uint8Array([4, 11, 253, 97, 148, 213, 134, 61, 78, 68, 200, 221, 159, 210, 80, 196, 142, 146, 41, 83, 86, 56, 37, 201, 173, 40, 240, 176, 101, 9, 65, 78, 204, 102, 164, 18, 56, 154, 140, 98, 69, 127, 43, 236, 159, 180, 148, 64, 69, 95, 115, 231, 93, 128, 211, 75, 189, 114, 255, 249, 130, 183, 49, 111, 78])
                    }).then(() => (swReg)) // Return swReg back to the next .then()
            })
            .then((swReg) => {
                swReg.pushManager.getSubscription()
                    .then((sub) => {
                        let body = {
                            subscription: sub.toJSON(),
                            message: 'Hey!'
                        };
                        console.log(body);
                        fetch('/push', {
                            method: 'POST',
                            body: JSON.stringify(body)
                        }).then((res) => {
                            console.log(res)
                        }).catch((err) => {
                            console.log(err)
                        })
                    })
            })
        navigator.serviceWorker.register('/serviceworker.js', {scope: './'})
            .then(function (reg) {
                console.log('[Companion]', 'Service worker registered!');
                console.log(reg);
            });
    }
});