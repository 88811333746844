export const RELATIVE_URL_ROOT = (process.env.RAILS_RELATIVE_URL_ROOT || '');

const axios = require('axios').default;

const defaults = {
    baseURL: `${RELATIVE_URL_ROOT}/`,
};

/*
const staging = {
    ...defaults,
    headers: {
        'Authorization': 'Basic ' + btoa("missiondata:missiondata")
    }
}
*/
export const axiosApi = axios.create(defaults);